import React, { useState, useEffect } from "react";
import styles from "./ChatAvatar.module.css";
interface ChatAvatarProps {
    isStreaming: boolean;
}

const ChatAvatar: React.FC<ChatAvatarProps> = ({ isStreaming }) => {
    return (
        <div className={styles.avatarbackground}>
            {isStreaming && <img src={"/assets/Peter_Gif_optimized.gif"} alt="Peter Gif Avatar" />}
            {!isStreaming && <img src={"/assets/Peter_Gif_optimized_src_wating.gif"} alt="Waiting" />}
        </div>
    );
};

export default ChatAvatar;
