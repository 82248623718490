import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import styles from "./VideoPlayback.module.css"; // Adjust the import path as needed
import { CookiesProvider, useCookies } from "react-cookie";
import ChatAvatar from "../../components/ChatAvatar/ChatAvatar";
import { Button } from "@fluentui/react-components";
import { AirplaneTakeOff24Filled, AirplaneTakeOff24Regular, ArrowEnter20Filled, Play24Filled } from "@fluentui/react-icons";

const BackgroundVideo = () => {
    const [isFirstVisit, setIsFirstVisit] = useState(true);
    const [isMuted, setIsMuted] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const playerRef = useRef();
    const exampleDivRef = useRef<HTMLDivElement>(null);
    const videoRootDiv = useRef<HTMLDivElement>(null);
    const [isVideoEnded, setIsVideoEnded] = useState(false);

    // const azureintro = "https://smcblob-euwe.streaming.media.azure.net/f48b74e6-715e-463e-9a4f-aa08d92fe1c2/Intro-FirstVisit.ism/manifest(format=m3u8-cmaf)";
    const azureintro = "https://youtu.be/7wvqlKJKTF0";
    // const azurerevisit = "https://smcblob-euwe.streaming.media.azure.net/c2eb0231-0ec3-4303-8218-cfe58c440cdc/Intro-ReVisit.ism/manifest(format=m3u8-cmaf)";
    const azurerevisit = "https://youtu.be/enEP8winflU";

    const [cookies, setCookie] = useCookies(["knownUser"]);

    useEffect(() => {
        const knownUser = cookies.knownUser;
        console.log(knownUser);

        if (knownUser == undefined || knownUser == false) {
            console.log("User visits first time");
            console.log("No Cookies Found");
            setCookie("knownUser", "true", { path: "/" });
            setIsFirstVisit(true);
        } else if (knownUser == true) {
            setIsFirstVisit(false);
            //localStorage.setItem("mbt_already_visited", "true");
            //setCookie("knownUser", "true", { path: "/" });
            console.log("User revisits");
        }
    }, []);

    const toggleMute = () => {
        setIsMuted(!isMuted);
    };

    const videoUrl = isFirstVisit
        ? azureintro // Replace with your YouTube video ID for the first visit
        : azurerevisit; // Replace with your YouTube video ID for subsequent visits

    const playerStyle = {
        transform: "translate(0, 0) scale(2.2)"
    };

    const startVideo = () => {
        if (playerRef.current) {
            // @ts-ignore
            playerRef.current.seekTo(0); // Optional: if you want to start from the beginning
            // @ts-ignore
            playerRef.current.getInternalPlayer().playVideo(); // Directly using the YouTube API
        }
        if (exampleDivRef.current) {
            exampleDivRef.current.classList.add("hidden");
            setTimeout(() => {
                if (exampleDivRef.current) {
                    exampleDivRef.current.style.display = "none";
                }
            }, 200);
        }
    };

    const handleVideoEnd = () => {
        setIsVideoEnded(true);
        if (videoRootDiv.current) {
            setTimeout(() => {
                if (videoRootDiv.current) {
                    videoRootDiv.current.style.display = "none";
                }
            }, 10); // Adjust the timeout to match your CSS transition duration
        }
    };

    return (
        <div ref={videoRootDiv} className={styles.videoRoot}>
            <div className={styles.youtubedivstyle}>
                {/* <ReactPlayer config={{ file: { forceHLS: true } }} url={`${videoUrl}`} playing muted={isMuted} controls width="75%" /> */}
                {/* <ReactPlayer config={{ file: { forceHLS: true } }} url={`${videoUrl}`} controls width="60%" height="60%" /> */}
                <ReactPlayer
                    // @ts-ignore
                    ref={playerRef}
                    url={`${videoUrl}`} // Replace [YourVideoID] with the actual YouTube video ID
                    playing={true} // Enables autoplay
                    muted={isMuted}
                    controls // Shows the video controls
                    //width="100%"
                    //height="200%"
                    onEnded={handleVideoEnd}
                    style={playerStyle}
                    config={{
                        youtube: {
                            playerVars: {
                                height: 100,
                                autoplay: 1,
                                controls: 0,
                                volume: 0.5,
                                muted: true,
                                disablekb: 1,
                                color: "white",
                                modestbranding: 1,
                                playsinline: 1,
                                showinfo: 0
                            } // YouTube player specific configurations
                        }
                    }}
                />
            </div>
            {isFirstVisit && (
                <div ref={exampleDivRef} className={styles.example} onClick={startVideo}>
                    <p className={styles.exampleText}>
                        <AirplaneTakeOff24Regular />
                        Starte Einführung
                    </p>
                </div>
            )}
            {!isFirstVisit && (
                <div ref={exampleDivRef} className={styles.example} onClick={startVideo}>
                    <p className={styles.exampleText}>
                        Willkommen zurück! <Play24Filled />
                    </p>
                </div>
            )}
            {isVideoEnded && <ChatAvatar isStreaming={false}></ChatAvatar>}
        </div>
    );
};

export default BackgroundVideo;
