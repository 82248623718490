import React, { useState, useEffect, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { LogEntry } from "../../pages/administrator/Adminstrator";
import styles from "./ChatLogContainer.module.css";

interface Props {
    filteredLogs: LogEntry[];
    loading: boolean;
    logsPerPage: number;
    length: number;
}

export const ChatLogContainer: React.FC<Props> = ({ filteredLogs, loading, logsPerPage, length }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [displayLogs, setDisplayLogs] = useState<LogEntry[]>([]);
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const [chatContainerHeight] = useState(500);

    useEffect(() => {
        setDisplayLogs(filteredLogs.slice(0, currentPage * logsPerPage));
    }, [currentPage, filteredLogs, logsPerPage]);

    const handleScroll = () => {
        // console.log(Math.abs(chatContainerRef.current?.scrollTop));
        // console.log(chatContainerRef.current?.scrollHeight);
        // console.log(chatContainerRef.current.scrollHeight - chatContainerHeight);
        if (chatContainerRef.current) {
            // Load more messages when the user is near the top of the scroll container
            if (
                Math.abs(chatContainerRef.current.scrollTop) >= chatContainerRef.current.scrollHeight - chatContainerHeight - 50 &&
                currentPage * logsPerPage < length
            ) {
                setCurrentPage(prevPage => prevPage + 1);
                console.log("Load more...");
            }
        }
    };

    useEffect(() => {
        const chatContainer = chatContainerRef.current;
        if (chatContainer) {
            chatContainer.addEventListener("scroll", handleScroll);

            // Cleanup the event listener on component unmount
            return () => {
                chatContainer.removeEventListener("scroll", handleScroll);
            };
        }
    }, [loading, currentPage, length, logsPerPage]);

    useEffect(() => {
        // Maintain the scroll position when new messages are loaded
        if (chatContainerRef.current && currentPage > 1) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight / currentPage;
        }
    }, [displayLogs, currentPage]);

    return (
        <div
            ref={chatContainerRef}
            className={styles.chatContainer}
            style={{ overflowY: "auto", height: chatContainerHeight + "px", display: "flex", flexDirection: "column-reverse" }}
        >
            {loading ? (
                <div>
                    {[...Array(2)].map((_, index) => (
                        <div key={index} className={styles.chatMessage}>
                            <div className={styles.userBubble}>
                                <span className={styles.timestamp}>
                                    <Skeleton />
                                </span>
                                <p>
                                    <Skeleton />
                                </p>
                                <p>
                                    <Skeleton />
                                </p>
                            </div>
                            <div className={styles.systemBubble}>
                                <p>
                                    <Skeleton />
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                displayLogs.map(log => (
                    <div key={log.id} className={styles.chatMessage}>
                        <div className={styles.userBubble}>
                            <span className={styles.timestamp}>
                                {log.timestamp} - {log.model}
                            </span>
                            <p>{log.user_input}</p>
                        </div>
                        <div className={styles.systemBubble}>
                            <p>{log.chat_output}</p>
                        </div>
                    </div>
                ))
            )}
        </div>
    );
};

export default ChatLogContainer;
